.register-section {
  position: relative;
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75));
  color: white;
  z-index: 1;
}

.register-form-container {
  background-color: rgba(30, 30, 45, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(239, 51, 76, 0.2);
  transition: all 0.3s ease;
  z-index: 1;
}

.register-form-container:hover {
  box-shadow: 0 15px 40px rgba(239, 51, 76, 0.15);
  border: 1px solid rgba(239, 51, 76, 0.3);
}

.heading-name {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  margin-bottom: 1rem;
}

.subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.register-form .form-label {
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.register-form .form-control,
.register-form .form-select {
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(239, 51, 76, 0.2);
  color: white;
  padding: 10px 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.register-form .form-control:focus,
.register-form .form-select:focus {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #EF334C;
  box-shadow: 0 0 0 0.25rem rgba(239, 51, 76, 0.25);
}

.register-form .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.submit-btn {
  background-color: #EF334C;
  border-color: #EF334C;
  padding: 10px 25px;
  font-weight: 500;
  width: 100%;
  transition: all 0.3s ease;
}

.submit-btn:hover:not(:disabled) {
  background-color: #d41c35;
  border-color: #d41c35;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.submit-btn:disabled {
  background-color: #f5a5ae;
  border-color: #f5a5ae;
  cursor: not-allowed;
}

.success-message {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.success-icon {
  font-size: 4rem;
  color: #28a745;
  margin-bottom: 20px;
}

.success-message h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333333;
  text-shadow: none;
}

.success-message p {
  color: #555555;
  font-size: 1.1rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.success-message .purple {
  color: #EF334C;
}

.success-message button {
  background: #EF334C !important;
  border: none;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.success-message button:hover {
  background: #d41e37 !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(239, 51, 76, 0.4);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dark theme styles for select options */
.register-form .form-select option {
  background-color: #1b1a2e;
  color: white;
}

/* Alert styles */
.alert-danger {
  background-color: rgba(239, 51, 76, 0.1);
  color: #ff8a8a;
  border: 1px solid rgba(239, 51, 76, 0.2);
  border-radius: 8px;
}

/* Required field indicator */
.text-danger {
  color: #f03e3e !important;
}

/* Form validation feedback */
.invalid-feedback, 
.form-control.is-invalid,
.form-select.is-invalid {
  color: #f03e3e;
  border-color: #f03e3e;
}

.form-text {
  font-size: 0.8rem;
  opacity: 0.8;
}

/* Disabled field styling */
.form-select:disabled {
  background-color: rgba(50, 50, 65, 0.5);
  cursor: not-allowed;
}

/* Year-specific validation styling */
.year-error,
.registration-type-error {
  margin-top: 0.25rem;
  color: #f03e3e;
  font-size: 0.875rem;
}

/* Conditional field styling */
.conditional-field {
  transition: all 0.3s ease;
  opacity: 1;
}

.conditional-field.disabled {
  opacity: 0.6;
}

/* Improved responsive adjustments */
@media (max-width: 768px) {
  .register-form-container {
    padding: 20px;
    margin: 0 10px;
  }
  
  .heading-name {
    font-size: 2rem;
  }
  
  .recaptcha-container {
    transform: scale(0.85);
    transform-origin: center center;
    margin-left: -12px;
  }
  
  .success-message {
    padding: 20px;
  }
  
  .success-message h2 {
    font-size: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 0.8rem;
  }
}

@media (max-width: 576px) {
  .register-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .heading-name {
    font-size: 1.8rem;
  }
  
  .success-icon {
    font-size: 3rem;
  }
  
  .submit-btn {
    padding: 8px 20px;
  }
  
  .recaptcha-container {
    transform: scale(0.7);
    transform-origin: center center;
    margin-left: -30px;
  }
  
  .subtitle {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
  
  .form-text {
    font-size: 0.7rem;
  }
}

@media (max-width: 375px) {
  .heading-name {
    font-size: 1.5rem;
  }
  
  .recaptcha-container {
    transform: scale(0.6);
    margin-left: -40px;
  }
  
  .register-form-container {
    padding: 15px;
  }
}