  #preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000000; 
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #preloader-none {
    display: none;
  }
  
  .new-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0c0513;
  }
  
  .loader {
    border: 16px solid #f3f3f3; 
    border-top: 16px solid #3498db; 
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }