.home-section {
  position: relative;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.95));
  min-height: 100vh;
  padding-top: 80px;
  display: flex;
  align-items: center;
}

.particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.home-content {
  z-index: 1;
  position: relative;
  padding: 40px 0;
}

.home-header {
  padding-top: 30px;
  padding-bottom: 30px;
}

.heading-welcome {
  font-size: 2.8rem;
  font-weight: 700;
  padding-bottom: 15px;
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.heading-name {
  font-size: 2.5rem;
  font-weight: 600;
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.main-name {
  color: #EF334C;
  font-weight: 700;
}

.type-container {
  padding: 50px 0 30px 0;
  text-align: left;
}

.home-img {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating {
  animation: floating 3s ease-in-out infinite;
  max-height: 450px;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

/* Home2 Component Styles */
.home-about-section {
  position: relative;
  padding: 80px 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.98));
  color: white;
  min-height: 100vh;
  z-index: 1;
}

.home-about-particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.home-about-content {
  position: relative;
  z-index: 2;
}

.home-about-description {
  padding: 30px 0;
}

.about-heading {
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.home-about-body {
  font-size: 1.1rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
}

.purple {
  color: #EF334C !important;
  font-weight: 600;
}

.myAvtar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.myAvtar img {
  box-shadow: 0 10px 20px rgba(239, 51, 76, 0.3);
  transition: all 0.3s ease;
  border: 3px solid rgba(239, 51, 76, 0.3);
  width: 80%;
  max-width: 300px;
}

.myAvtar img:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(239, 51, 76, 0.4);
}

.home-about-social {
  text-align: center;
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.home-about-social h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.home-about-social p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.home-about-social-links {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 0;
}

.social-icons {
  margin: 0 15px;
}

.icon-colour {
  color: white !important;
}

.home-social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #EF334C;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.home-social-icons:hover {
  background-color: white;
  color: #EF334C !important;
  box-shadow: 0 5px 15px rgba(239, 51, 76, 0.4);
  transform: translateY(-3px);
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 767px) {
  .heading-welcome {
    font-size: 2.2rem;
    text-align: center;
  }

  .heading-name {
    font-size: 2rem;
    text-align: center;
  }

  .type-container {
    text-align: center;
    padding: 30px 0;
  }
  
  .home-img {
    padding-top: 20px;
  }
  
  .floating {
    max-height: 350px;
  }
  
  .about-heading {
    font-size: 2.2rem;
    text-align: center;
  }
  
  .home-about-body {
    text-align: center;
    font-size: 1rem;
  }
  
  .myAvtar {
    margin-top: 30px;
  }
  
  .home-about-social h1 {
    font-size: 1.8rem;
  }
} 